import styled from "styled-components";
import Button from "../button";

const StyledSection = styled.section`
  min-height: 750px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
  }
`;

const StyledDiv = styled.div`
  width: 65%;
  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StyledEpigraph = styled.p`
  color: #ff9999;
  font-size: 1.2em;
`;

const StyledTitle = styled.h1`
  font-size: 4em;
  font-family: "Poppins";
  color: #ff9999;
  line-height: 1;
`;
const StyledSubtitle = styled.h3`
  font-size: 4em;
  font-family: "Poppins";
  text-align: start;
  line-height: 1;
`;
const StyledParagraph = styled.p`
  font-size: 1.2em;
  letter-spacing: 0.5px;
  margin: 24px 0 24px 0;
  width: 80%;
  @media (max-width: 800px) {
    width: 100%;
    font-size: 1em;
  }
`;

const Hero = () => {
  return (
    <StyledSection>
      <StyledDiv>
        <StyledEpigraph>Hi, my name is</StyledEpigraph>
        <StyledTitle>Federico Díaz.</StyledTitle>
        <StyledSubtitle>I'm a web developer.</StyledSubtitle>
        <StyledParagraph>
          Driven by curiosity, I'm always learning new technologies to improve
          my skills as a software developer. I love web development,
          nevertheless I've built apps both in desktop and mobile as well.
        </StyledParagraph>
        <Button text={"Download my Resume"} url={"resume.pdf"} isFile />
      </StyledDiv>
    </StyledSection>
  );
};

export default Hero;
