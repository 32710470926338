import AboutMe from "./components/sections/about";
import Contact from "./components/sections/contact";
import Experience from "./components/sections/experience";
import Hero from "./components/sections/hero";
import Navbar from "./components/sections/navbar";
import Projects from "./components/sections/projects";

const App = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <AboutMe />
      <Experience />
      <Projects />
      <Contact />
    </>
  );
};

export default App;
