import styled from "styled-components";

const StyledProjectContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  margin: 30px 0 30px 0;
  @media (max-width: 1000px) {
    flex-direction: column;
    height: auto;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    max-height: 400px;
  }
`;

const StyledInformationContainer = styled.div`
  padding: 20px;
  width: 35%;
  height: 300px;
  background-color: rgb(255, 200, 200, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  right: 50%;
  @media (max-width: 1000px) {
    position: static;
    width: 100%;
    height: auto;
  }
`;
const StyledTitle = styled.h1`
  background-color: inherit;
  color: #000;
  margin-bottom: 10px;
`;
const StyledDescription = styled.p`
  background-color: inherit;
  color: #000;
  margin-bottom: 10px;
`;

const StyledStack = styled.p`
  background-color: inherit;
  color: #000;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  width: 75%;
  height: 100%;
  opacity: 0.7;
  filter: drop-shadow(5px 5px 5px pink);
  @media (max-width: 1000px) {
    width: 100%;
    max-height: 400px;
  }
  &:hover {
    opacity: 1;
    background: pink;
  }
`;

const Project = ({ name, img, url, description, stack }) => {
  return (
    <StyledProjectContainer>
      <StyledAnchor href={url}>
        <StyledImage src={img} />
      </StyledAnchor>
      <StyledInformationContainer>
        <StyledTitle>{name}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledStack>Tech Stack: {stack.map((s) => s + ", ")}</StyledStack>
      </StyledInformationContainer>
    </StyledProjectContainer>
  );
};

export default Project;
