import styled from "styled-components";

const StyledSectionContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

const StyledTitleContainer = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const StyledTitle = styled.h1`
  color: #ffc8c8;
  font-size: 2.5em;
  font-weight: 700;
  line-height: 2;
`;

const StyledSpan = styled.span`
  color: #fffbeb;
`;

const StyledContactContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
  }
`;

const StyledParagraph = styled.p`
  color: #fffbeb;
  font-size: 1.2em;
`;

const StyledIconsContainer = styled.div`
  padding: 100px 0 50px 0;
  width: 40%;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const StyledIcon = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const StyledAnchor = styled.a`
  &:hover {
    color: #fffbeb;
    transform: scale(1.2);
  }
`;

const Contact = () => {
  return (
    <StyledSectionContainer id="contact-me">
      <StyledTitleContainer>
        <StyledTitle>Contact Me!</StyledTitle>
      </StyledTitleContainer>
      <StyledContactContainer>
        <StyledParagraph>
          If you want to know more about me or in case you have any question,
          feel free to reach me through any of my social network profiles.
        </StyledParagraph>
      </StyledContactContainer>
      <StyledIconsContainer>
        <StyledAnchor href="https://www.linkedin.com/in/federicodiazgerstner">
          <StyledIcon src="linkedin.svg" />
        </StyledAnchor>
        <StyledAnchor href="https://www.github.com/federicodiazgerstner">
          <StyledIcon src="github.svg" />
        </StyledAnchor>
        <StyledAnchor href="mailto:federicodiazgerstner@gmail.com">
          <StyledIcon src="email.svg" />
        </StyledAnchor>
      </StyledIconsContainer>
    </StyledSectionContainer>
  );
};

export default Contact;
