import styled from 'styled-components';

const StyledPrimaryButton = styled.button`
  width: 200px;
  border: 1px solid #ffc8c8;
  padding: 20px;
  font-weight: bold;
  &:hover,
  &:active {
    background-color: #ffc8c8;
    color: #fff;
    cursor: pointer;
  }
  transition: background-color 0.2s ease;
  transition: color 0.2s ease;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
`;

const Button = ({ text, variant = 'primary', url, isFile }) => {
  return (
    <>
      {isFile ? (
        <StyledAnchor href='resume.pdf' download>
          <StyledPrimaryButton>{text}</StyledPrimaryButton>
        </StyledAnchor>
      ) : (
        <StyledAnchor href={url}>
          <StyledPrimaryButton>{text}</StyledPrimaryButton>
        </StyledAnchor>
      )}
    </>
  );
};

export default Button;
