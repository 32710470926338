import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Project from "./project";

const StyledSlider = styled(Slider)`
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledProjectContainer = styled.div`
  width: 400px;
  height: 600px;
  background-color: pink;
`;

const StyledProjectImgContainer = styled.div`
  width: 70%;
  height: 600px;
  background-color: blue;
`;
const StyledProjectDescriptionContainer = styled.div`
  wdith: 20%;
  height: 250px;
  background-color: red;
`;

const ProjectSlider = () => {
  const projects = [
    {
      name: "Recovery Pal",
      img: "project1.png",
      url: "",
      description: "lorem ipsum blablablablablablablablablabla",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return (
    <StyledSlider {...settings}>
      <Project {...projects[0]} />
      <Project {...projects[0]} />
    </StyledSlider>
  );
};

export default ProjectSlider;
