import { useState } from "react";
import styled from "styled-components";
import Button from "../button";

const StyledSectionContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
`;

const StyledTitleContainer = styled.div`
  width: 65%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 30px;
  }
`;

const StyledTitle = styled.h1`
  color: #ff9999;
  font-size: 2em;
  font-weight: 700;
  line-height: 2;
`;

const StyledSpan = styled.span`
  color: #fffbeb;
`;

const StyledExpContainer = styled.div`
  width: 65%;
  display: flex;
  margin-top: 20px;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    flex-direction: column;
  }
`;

const StyledCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    flex-direction: row;
  }
`;

const StyledExpDetails = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 20px 0;
  }
`;

const StyledExpTitle = styled.h3`
  color: #fffbeb;
`;

const StyledExpTitleCompany = styled.a`
  text-decoration: none;
  color: #ff9999;
`;

const StyledExpPeriod = styled.p`
  color: #fffbeb;
  margin-bottom: 10px;
`;

const StyledList = styled.ul`
  margin-right: 20px;
  padding: 20px;
  @media (max-width: 1000px) {
    padding: 0 10px;
  }
`;

const StyledListItem = styled.li`
  color: #fffbeb;
`;

const StyledPrimaryButton = styled.button`
  width: 200px;
  border: 1px solid #ffc8c8;
  padding: 20px;
  font-weight: bold;
  &:hover {
    background-color: #ffc8c8;
    color: #fff;
    cursor: pointer;
  }
  transition: background-color 0.2s ease;
  transition: color 0.2s ease;
`;
const Experience = () => {
  const experiences = [
    {
      company: "Amherst",
      site_url: "https://www.amherst.com",
      from: "May 2022",
      to: "act.",
      role: "Full Stack Support Engineer",
      items: [
        "Development of new front-end features for the Leasing team using React.js, React Hooks, Tailwind, ESLint, Next.js, Vercel.",
        "Dcoumentation of components using Storybook.",
        "Investigating application bugs through Splunk logs.",
        "Manual QA for both web applications as well as mobile application using Charles Proxy, Android Studio, Dev Tools, Postman.",
        "QA Automation using Playwright.",
        "Test Cases creation and Test Scenarios definitions using JIRA's Zephyr add-on.",
      ],
      techs: ["React.js", "Node.js", "SQL Server", "Next.js"],
    },
    {
      company: "BairesDev",
      site_url: "https://www.bairesdev.com",
      from: "Apr 2021",
      to: "Apr 2022",
      role: "R&D Support Engineer",
      items: [
        "Implementation of new processes using SQL Stored Procedures.",
        "JIRA and BitBucket for sprint management, deployment. Jenkins for CI/CD.",
        "Development of new features for the company's ERP and fixing bugs (C# .NET)",
        "Leadership of new support engineers.",
      ],
      techs: ["SQL Server", "C# .NET"],
    },
  ];

  const [selectedExp, setSelectedExp] = useState(0);

  const handleClickExperience = (e) => {
    console.log(e.target.value);
    setSelectedExp(e.target.value);
  };

  return (
    <StyledSectionContainer id="experience">
      <StyledTitleContainer>
        <StyledTitle>
          02. <StyledSpan>Experience</StyledSpan>
        </StyledTitle>
      </StyledTitleContainer>
      <StyledExpContainer>
        <StyledCompanyContainer>
          {experiences.map((exp, key) => (
            <StyledPrimaryButton
              key={key}
              value={key}
              selectedExp={selectedExp}
              onClick={handleClickExperience}
            >
              {exp.company}
            </StyledPrimaryButton>
          ))}
        </StyledCompanyContainer>
        <StyledExpDetails>
          <StyledExpTitle>
            {experiences[selectedExp].role} @{" "}
            <StyledExpTitleCompany href={experiences[selectedExp].site_url}>
              {experiences[selectedExp].company}
            </StyledExpTitleCompany>
          </StyledExpTitle>
          <StyledExpPeriod>
            {experiences[selectedExp].from} - {experiences[selectedExp].to}
          </StyledExpPeriod>
          <StyledList>
            {experiences[selectedExp].items.map((item, key) => (
              <StyledListItem key={key}>{item}</StyledListItem>
            ))}
          </StyledList>
        </StyledExpDetails>
      </StyledExpContainer>
    </StyledSectionContainer>
  );
};

export default Experience;
