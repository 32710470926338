import styled from "styled-components";

const StyledSection = styled.section`
  width: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  flex-direction: column;
`;

const StyledTitleContainer = styled.div`
  width: 65%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 30px;
  }
`;

const StyledTitle = styled.h1`
  color: #ff9999;
  font-size: 2em;
  font-weight: 700;
  line-height: 2;
`;

const StyledSpan = styled.span`
  color: #fffbeb;
`;

const StyledContentContainer = styled.div`
  width: 65%;
  display: flex;

  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const StyledTextContainer = styled.div`
  width: 50%;
  font-size: 1.2em;
  letter-spacing: 0.5px;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    font-size: 1em;
  }
`;

const StyledImgContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
  }
`;

const StyledImage = styled.img`
  width: 300px;
  height: 300px;
  box-shadow: 10px 10px 10px pink;
`;

const StyledTechContainer = styled.div`
  margin-top: 50px;
  width: 65%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
  }
`;
const StyledSubtitle = styled.h2`
  font-size: 1.4em;
  font-weight: 700;
  color: #fffbeb;
`;

const StyledListsContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledList = styled.ul`
  flex: 1;
`;

const StyledListItem = styled.li`
  color: #ff9999;
  font-size: 1.2em;
  margin: 0 20px;
`;

const AboutMe = () => {
  return (
    <StyledSection id="about-me">
      <StyledTitleContainer>
        <StyledTitle>
          01. <StyledSpan>About Me</StyledSpan>
        </StyledTitle>
      </StyledTitleContainer>
      <StyledContentContainer>
        <StyledTextContainer>
          {/*My interest in software development began back in 2018, when I was
          still going through the Public Accountant program. At that time, I
          started watching videos and reading about web development with HTML,
          CSS and Javascript. Eager to start learning more, I decided to pursue
          my desire of becoming a developer and left the accountant program to
          sign up for the Software Development program at UADE in 2020. <br />
          <br />
          Since then, I've switched my job position to work as a Software
          Support Engineer, where I learned a lot about logic, SQL and C# .NET.
          But I knew I wanted to pursue a career as a web developer, so I
          changed my job to start working for Amherst as a FStyledListl Stack Support
          Engineer, where I began to work professionally with technologies such
          as React, Javascript, Node.js, SQL, AWS and Next.js.
          <br />
          <br />
          Also, during my projects for university lessons, me and my teammates
          built many apps that helped me understand more about development and
          become a better professional, by learning the process of software
          development from the start of a common problem for anyone until the
          development of a tech product to solve it.*/}
          Started learning programming in 2018, watching videos and reading
          about web development using HTML, CSS and Javascript. Since then, I've
          taken many courses related to Front-End Development, Algorithms and
          Data Structures, Responsive Web Design, and many more. <br />
          <br />
          In 2020, I've signed up for the Software Development program at UADE
          University, to continue learning more topics related to my career.
          This helped me become a better professional as well as a better
          developer
        </StyledTextContainer>
        <StyledImgContainer>
          <StyledImage src="fede.png" />
        </StyledImgContainer>
      </StyledContentContainer>
      <StyledTechContainer>
        <StyledSubtitle>Skills and Tech Stack</StyledSubtitle>
        <StyledListsContainer>
          <StyledList>
            <StyledListItem>HTML</StyledListItem>
            <StyledListItem>CSS</StyledListItem>
            <StyledListItem>Javascript</StyledListItem>
            <StyledListItem>React.js</StyledListItem>
          </StyledList>
          <StyledList>
            <StyledListItem>Node.js</StyledListItem>
            <StyledListItem>Express.js</StyledListItem>
            <StyledListItem>MongoDB</StyledListItem>
            <StyledListItem>SQL Server</StyledListItem>
          </StyledList>
          <StyledList>
            <StyledListItem>React Native</StyledListItem>
            <StyledListItem>Postman</StyledListItem>
            <StyledListItem>Git</StyledListItem>
            <StyledListItem>Cloudinary</StyledListItem>
          </StyledList>
        </StyledListsContainer>
      </StyledTechContainer>
    </StyledSection>
  );
};

export default AboutMe;
