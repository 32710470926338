import styled from "styled-components";
import Project from "../project";
import ProjectSlider from "../slider";

const StyledSectionContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
`;

const StyledTitleContainer = styled.div`
  width: 65%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 30px;
  }
`;

const StyledTitle = styled.h1`
  color: #ff9999;
  font-size: 2em;
  font-weight: 700;
  line-height: 2;
`;

const StyledSpan = styled.span`
  color: #fffbeb;
`;

const StyledProjectsContainer = styled.div`
  width: 65%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
  }
`;

const StyledAnchor = styled.a`
  border: 1px solid #ffc8c8;
  margin-top: 30px;
  padding: 20px;
  text-decoration: none;
  font-size: 1.2em;
  color: #ffc8c8;
  background-color: inherit;
  &:hover {
    cursor: pointer;
    background-color: #ffc8c8;
    color: #fffbeb;
  }
`;

const Projects = () => {
  const projects = [
    {
      name: "Recovery Pal",
      img: "recoverypal.jpeg",
      url: "https://github.com/aguscoppe/recovery-pal-front",
      description:
        "Platform for kinesiologists and patients to centralize home routines, allowing doctors to follow their patients progress, create new routines, answer messages, while the patients can go through their routines and earn achivements",
      stack: [
        "React.js",
        "Material UI",
        "Node.js",
        "Express.js",
        "MongoDB",
        "Mongoose",
        "Cloudinary",
      ],
    },
    {
      name: "Morfando Inc",
      img: "morfandoinc.jpeg",
      url: "https://gitlab.com/pedrosevaseva/distribuidas-front",
      description:
        "CRUD Application for restaurant owners and clients. Owners can create and publish their restaurants, and clients can rate them, see which ones are closer, share them and see their details",
      stack: [
        "React Native",
        "Expo",
        "Firebase",
        "Node.js",
        "Express.js",
        "MongoDB",
        "Mongoose",
        "React Native Picker",
      ],
    },
    {
      name: "Course Hero",
      img: "coursehero.jpeg",
      url: "https://github.com/aguscoppe/tp-marketplace",
      description:
        "Web App through which professors can create their courses, accept/deny sign up request from the students. Students can sign up for courses, comment and rate them.",
      stack: [
        "React.js",
        "Node.js",
        "Nest.js",
        "Docker",
        "MySQL",
        "Material UI",
      ],
    },
  ];

  return (
    <StyledSectionContainer id="projects">
      <StyledTitleContainer>
        <StyledTitle>
          03. <StyledSpan>Projects</StyledSpan>
        </StyledTitle>
      </StyledTitleContainer>
      <StyledProjectsContainer>
        {projects.map((project, key) => {
          return <Project {...project} key={key} />;
        })}
      </StyledProjectsContainer>
      <StyledAnchor
        href="https://www.github.com/federicodiazgerstner"
        target="_blank"
      >
        {" "}
        Check out more of my work here!
      </StyledAnchor>
    </StyledSectionContainer>
  );
};

export default Projects;
