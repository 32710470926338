import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { useEffect } from "react";

const StyledNavbar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: fixed;
  padding: 0px 20px;
  top: 0;
  z-index: 99;
  @media (max-width: 800px) {
    padding: 36px 20px;
  }
`;

const StyledDivLogo = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  border-radius: 50%;
`;
const StyledDivSections = styled.div`
  width: 40%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    width: 60%;
  }
`;

const StyledAnchor = styled.a`
  color: #ff9999;
  font-size: 1em;
  text-decoration: none;
  @media (max-width: 800px) {
    color: #000;
  }
`;

const StyledSpan = styled.span`
  color: #fffbeb;
  &:hover {
    color: #ff9999;
  }
  @media (max-width: 800px) {
    color: #000;
    background-color: #ffc8c8;
  }
`;

const StyledImg = styled.img`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
`;

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "20px",
    top: "20px",
  },
  bmBurgerBars: {
    background: "#ff9999",
  },
  bmBurgerBarsHover: {
    background: "#ff9999",
  },
  bmCrossButton: {
    height: "36px",
    width: "36px",
    background: "#FFC8C8",
  },
  bmCross: {
    background: "#000",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: 0,
    boxShadow: "10px 10px 10px 10px black",
  },
  bmMenu: {
    background: "#FFC8C8",
    fontSize: "1.15em",
    padding: "20px",
  },
  bmMorphShape: {
    fill: "#FFC8C8",
  },
  bmItemList: {
    padding: "10px",
    color: "#000",
    backgroundColor: "#FFC8C8",
    textAlign: "start",
  },
  bmItem: {
    display: "block",
    backgroundColor: "inherit",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

const Navbar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 800;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
  }, []);
  return (
    <StyledNavbar>
      <StyledDivLogo>
        <StyledAnchor href="/">
          <StyledImg src="logot.png" alt="Federico Diaz Gerstner" />
        </StyledAnchor>
      </StyledDivLogo>
      {width < breakpoint ? (
        <Menu right styles={styles} onClick={(e) => e.preventDefault()}>
          <StyledAnchor href="#about-me">
            01. <StyledSpan>About Me</StyledSpan>
          </StyledAnchor>
          <StyledAnchor href="#experience">
            02. <StyledSpan>Experience</StyledSpan>
          </StyledAnchor>
          <StyledAnchor href="#projects">
            03. <StyledSpan>Projects</StyledSpan>
          </StyledAnchor>
          <StyledAnchor href="#contact-me">
            04. <StyledSpan>Contact</StyledSpan>
          </StyledAnchor>
        </Menu>
      ) : (
        <StyledDivSections>
          <StyledAnchor href="#about-me">
            01. <StyledSpan>About Me</StyledSpan>
          </StyledAnchor>
          <StyledAnchor href="#experience">
            02. <StyledSpan>Experience</StyledSpan>
          </StyledAnchor>
          <StyledAnchor href="#projects">
            03. <StyledSpan>Projects</StyledSpan>
          </StyledAnchor>
          <StyledAnchor href="#contact-me">
            04. <StyledSpan>Contact</StyledSpan>
          </StyledAnchor>
        </StyledDivSections>
      )}
    </StyledNavbar>
  );
};

export default Navbar;
